<template>
  <figure class="logo">
    <img :src="require('@/assets/logo-dilypse.svg')" alt="Logo" />
  </figure>
  <div class="card" id="login-container" v-cloak>
    <div class="card-header">Connexion à votre comptes</div>
    <div class="card-body">
      <dilypse-login-form />
    </div>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component'

import FloatingLabel from '@/components/DataEntry/FloatingLabel/FloatingLabel.vue'
import DilypseLoginForm from './DilypseLoginForm/DilypseLoginForm.vue'
@Options({
  name: 'DilypseLogin',
  components: {
    FloatingLabel,
    DilypseLoginForm,
  },
})
export default class DilypseLogin extends Vue {}
</script>
