<template>
  <form id="form-login" @submit="validateForm">
    <p v-if="touched && isUserValid >= 400" class="text-danger text-center">
      Email ou mot de passe invalide
    </p>
    <a-form
      ref="formLoginRef"
      :rules="rules"
      :model="formData"
      :hideRequiredMark="true"
      :colon="false"
    >
      <floating-form-item
        html-type="email"
        label="Adresse email"
        name="username"
        ref="username"
        v-model="formData.username"
        @input="touched = false"
      />
      <floating-form-item
        html-type="password"
        label="Votre mot de passe"
        name="password"
        ref="password"
        v-model="formData.password"
        @input="touched = false"
      />
      <p class="btn-wrapper">
        <button-validation-component
          :loading="loading"
          @click="handleConnexion"
        >
          Connectez-vous
        </button-validation-component>
      </p>
    </a-form>
    <p class="mdp-link">
      <a href=""><i class="dicon dicon-cadenas"></i> Mot de passe oublié ?</a>
    </p>
  </form>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

import FloatingFormItem from '@/components/DataEntry/FloatingLabel/FloatingFormItem.vue'
import ButtonValidationComponent from '@/components/General/Button/ButtonValidation.vue'
import { mapActions, mapGetters } from 'vuex'
import { toRaw } from 'vue'

import Form from 'ant-design-vue/lib/form'

interface ILoginData {
  errors: string[]
  rules: unknown
  loading: boolean
  formData: {
    username: String
    password: String
  }
  submitError: false
  touched: boolean
}

const rules = {
  username: [
    {
      required: true,
      message: 'Ce champ est obligatoire',
      trigger: 'blur',
    },
    {
      pattern: /^(.+)@(.+){2,}\.(.+){2,}$/,
      message: "L'email est invalide",
      trigger: 'change',
    },
  ],
  password: [
    {
      required: true,
      message: 'Ce champ est obligatoire',
      trigger: 'blur',
    },
  ],
}

@Options({
  name: 'DilypseLoginForm',
  components: {
    FloatingFormItem,
    ButtonValidationComponent,
    AForm: Form,
  },
  computed: {
    ...mapGetters(['getLoginData']),
    isUserValid() {
      return this.getLoginData.code
    },
  },
  data(): ILoginData {
    return {
      touched: false,
      submitError: false,
      loading: false,
      errors: [],
      formData: {
        username: '',
        password: '',
      },
      rules,
    }
  },
  methods: {
    ...mapActions(['setLogin', 'resetLogin']),
    async handleConnexion() {
      try {
        this.resetLogin()

        this.loading = true
        this.touched = true
        const { formLoginRef } = this.$refs
        await formLoginRef.validate()
        await this.setLogin(toRaw(this.formData))
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
  },
})
export default class DilypseLoginForm extends Vue {}
</script>
